//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import arrowOrange from '@/assets/imgs/arrow-orange.svg';
import arrow from '@/assets/imgs/arrow.svg';
import logo from '@/assets/imgs/logo-full.svg';

export default {
  name: 'KnockFooter',

  data() {
    return {
      arrowOrange,
      arrow,
      logo
    };
  },

  methods: {
    scrollToTop() {
      const body = document.querySelector('body');
      window.scrollTo({ top: body, behavior: 'smooth' });
    }
  }
};
