import Vue from 'vue';

export const state = () => ({
  menus: {}
});

export const getters = {
  bySlug: (state) => (slug) => state.menus[slug]
};

export const mutations = {
  SET: (state, { slug, menu }) => {
    Vue.set(state.menus, slug, menu);
  }
};

export const actions = {
  fetchBySlug: async ({ commit }, { slug, context: { $wpJson } }) => {
    let menu;
    try {
      menu = await $wpJson.menuItems.getBySlug(slug, { asTree: true });
    }
    catch (err) {
      console.error(err);
    }

    commit('SET', { slug, menu });
  }
};
