export default (request) => ({
  getBySlug: async (slug) => {
    const response = await request({
      method: 'GET',
      url: '/wp/v2/posts',
      params: {
        slug,
        acf_format: 'standard'
      }
    });

    return response.data?.[0];
  },

  getPreviews: async ({
    page = 1,
    perPage = 7,
    orderby = 'date',
    order = 'desc',
    categories,
    search
  } = {}) => {
    let category;
    if (categories === 1) {
      category = undefined;
    }
    else {
      category = categories;
    }
    const response = await request({
      method: 'GET',
      url: '/wp/v2/posts',
      params: {
        page,
        per_page: perPage,
        orderby,
        order,
        categories: category,
        search,
        acf_format: 'standard',
        _fields: 'id,slug,title,_embedded,_links.wp:featuredmedia,categories,date,acf',
        _embed: true
      }
    });

    const posts = response.data.map((post) => ({
      ...post,
      thumbnail: post?._embedded?.['wp:featuredmedia']?.[0],
      author: post?.acf.author
    }));

    const total = parseInt(response.headers['x-wp-total']);

    return { posts, total };
  },

  getCats: async () => {
    const response = await request({
      method: 'GET',
      url: '/wp/v2/categories',
      params: {
        per_page: 100
      }
    });

    return response.data;
  }
});
