export default (request) => ({
  getBySlug: async (fieldNames) => {
    const response = await request({
      url: '/knock/v1/options',
      method: 'GET',
      data: {
        field_names: fieldNames.join(',')
      }
    });

    return response.data;
  }
});
