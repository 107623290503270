export default (request) => ({
  getBySlug: async (slug) => {
    const response = await request({
      method: 'GET',
      url: '/wp/v2/pages',
      params: {
        slug,
        acf_format: 'standard'
      }
    });

    return response.data?.[0];
  }
});
