export default (request) => ({
  getBySlug: async (slug, { asTree = true } = {}) => {
    const response = await request({
      method: 'GET',
      url: '/knock/v1/menu-items',
      params: { slug }
    });

    let menuItems = response.data;

    if (asTree) {
      menuItems = menuItems
        .map((menuItem) => {
          menuItem.children = menuItems
            .filter((item) => item.menu_item_parent === `${menuItem.ID}`)
            .sort((a, b) => a.sort_order - b.sort_order);

          menuItem.has_children = menuItem.children.length > 0;

          return menuItem;
        })
        .filter((item) => item.menu_item_parent === '0')
        .sort((a, b) => a.sort_order - b.sort_order);
    }

    return menuItems;
  }
});
