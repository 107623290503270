import { render, staticRenderFns } from "./default.vue?vue&type=template&id=55da8067&scoped=true&"
import script from "./default.vue?vue&type=script&lang=js&"
export * from "./default.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55da8067",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {KnockHeader: require('/builds/knockmedia/knock-vi-web/src/components/KnockHeader.vue').default,KnockFooter: require('/builds/knockmedia/knock-vi-web/src/components/KnockFooter.vue').default})
