import axios from 'axios';

import createGfApi from './modules/gf';
import createMediaApi from './modules/media';
import createMenuItemsApi from './modules/menu-items';
import createOptionsApi from './modules/options';
import createPagesApi from './modules/pages';
import createPostsApi from './modules/posts';

export default ({ $config }, inject) => {
  const request = axios.create({
    baseURL: `${$config.wpApiUrl}/wp-json`
  });

  const wpJson = {
    gf: createGfApi(request),
    menuItems: createMenuItemsApi(request),
    options: createOptionsApi(request),
    pages: createPagesApi(request),
    posts: createPostsApi(request),
    media: createMediaApi(request)
  };

  inject('wpJson', wpJson);
};
