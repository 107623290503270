//
//
//
//
//
//

export default {
  name: 'KnockLayoutError',

  props: {
    error: { type: Object, required: true }
  },

  mounted() {
    this.$router.replace('/');
  }
};
