export default (request) => ({
  submit: async (id, data) => {
    const response = await request({
      method: 'POST',
      url: `/gf/v2/forms/${id}/submissions`,
      data
    });

    return response.data;
  }
});
