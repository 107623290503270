//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import logo from '@/assets/imgs/logo-nav.svg';

export default {
  name: 'KnockHeader',

  data() {
    return {
      logo,
      open: false,
      scrolled: false
    };
  },

  computed: {
    mainMenu() {
      return this.$store.getters['menus/bySlug']('main-menu');
    }
  },
  beforeMount() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },

  methods: {
    handleScroll() {
      this.scrolled = window.scrollY > 0;
    }
  }

};
