export default (request) => ({
  getByID: async (id) => {
    const response = await request({
      method: 'GET',
      url: '/wp/v2/media',
      params: { id }
    });

    return response.data?.[0];
  }
});
